import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import PriceUpdater from './components/PriceUpdater';
import ImageSearch from './components/ImageSearch';
import ImageProcessing from './components/ImageProcessing';
import BatchDigitalProcessor from './components/BatchDigitalProcessor';

const { Header, Content, Footer } = Layout;

const App = () => {
  return (
    <Router>
      <Layout className="layout" style={{ minHeight: '100vh' }}>
        <Header>
          <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
            <Menu.Item key="1">
              <Link to="/">Обновление цен</Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link to="/image-search">Поиск изображений</Link>
            </Menu.Item>
            <Menu.Item key="3">
              <Link to="/image-processing">Обработка изображений</Link>
            </Menu.Item>
            <Menu.Item key="4">
              <Link to="/batch-digital">Пакетная обработка</Link>
            </Menu.Item>
          </Menu>
        </Header>
        <Content style={{ padding: '0 50px', marginTop: 64 }}>
          <div className="site-layout-content" style={{ background: '#fff', padding: 24, minHeight: 380 }}>
            <Routes>
              <Route path="/" element={<PriceUpdater />} />
              <Route path="/image-search" element={<ImageSearch />} />
              <Route path="/image-processing" element={<ImageProcessing />} />
              <Route path="/batch-digital" element={<BatchDigitalProcessor />} />
            </Routes>
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>Инструмент обновления цен и поиска изображений ©2024</Footer>
      </Layout>
    </Router>
  );
};

export default App;