import React, { useState } from 'react';
import { Upload, Button, message, Card, Table, Spin } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

const BatchDigitalProcessor = () => {
  const [loading, setLoading] = useState(false);
  const [processedData, setProcessedData] = useState([]);
  const [downloadUrl, setDownloadUrl] = useState(null);

  const columns = [
    {
      title: 'Название игры',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Платформа',
      dataIndex: 'platform',
      key: 'platform',
    },
    {
      title: 'Исходное изображение',
      dataIndex: 'originalImage',
      key: 'originalImage',
      render: (text) => <a href={text} target="_blank" rel="noopener noreferrer">Просмотр</a>,
    },
    {
      title: 'Обработанное изображение',
      dataIndex: 'processedImage',
      key: 'processedImage',
      render: (text) => text ? <a href={text} target="_blank" rel="noopener noreferrer">Просмотр</a> : 'Обработка...',
    },
  ];

  const handleUpload = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch('/api/batch_process_digital', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Ошибка при обработке файла');
      }

      const result = await response.json();
      setProcessedData(result.processedGames);
      setDownloadUrl(result.downloadUrl);
      message.success('Файл успешно обработан!');
    } catch (error) {
      message.error(`Ошибка: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const uploadProps = {
    name: 'file',
    accept: '.xlsx, .xls',
    multiple: false,
    beforeUpload: (file) => {
      const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || 
                      file.type === 'application/vnd.ms-excel';
      if (!isExcel) {
        message.error('Можно загружать только Excel файлы!');
        return false;
      }
      handleUpload(file);
      return false;
    },
  };

  return (
    <div className="p-4">
      <Card title="Пакетная обработка цифровых версий игр">
        <Dragger {...uploadProps} disabled={loading}>
          <p className="ant-upload-drag-icon">
            <UploadOutlined />
          </p>
          <p className="ant-upload-text">
            Нажмите или перетащите Excel файл для загрузки
          </p>
          <p className="ant-upload-hint">
            Поддерживаются файлы Excel с колонками: Название игры, Ссылка на картинку, Платформа
          </p>
        </Dragger>

        {loading && (
          <div className="text-center my-4">
            <Spin size="large" />
            <p>Обработка изображений...</p>
          </div>
        )}

        {processedData.length > 0 && (
          <div className="mt-4">
            <Table 
              dataSource={processedData} 
              columns={columns} 
              rowKey="name"
              pagination={false}
            />
          </div>
        )}

        {downloadUrl && (
          <div className="mt-4 text-center">
            <Button type="primary" href={downloadUrl} download>
              Скачать обработанную таблицу
            </Button>
          </div>
        )}
      </Card>
    </div>
  );
};

export default BatchDigitalProcessor;