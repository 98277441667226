import React, { useState, useEffect } from 'react';
import { Upload, Button, message, Card, Image, Table, Spin } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const API_URL = 'https://gamer-zone-xbox.online/api';

const ImageSearch = () => {
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [imageResults, setImageResults] = useState([]);
  const [selectedImages, setSelectedImages] = useState({});
  const [finalResults, setFinalResults] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const [taskStatus, setTaskStatus] = useState(null);

  useEffect(() => {
    let intervalId;
    if (taskId) {
      intervalId = setInterval(async () => {
        try {
          const response = await fetch(`${API_URL}/task_status/${taskId}`);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setTaskStatus(data.status);
          if (data.state === 'SUCCESS') {
            setImageResults(data.results);
            setProcessing(false);
            clearInterval(intervalId);
            message.success('Обработка изображений завершена успешно');
          } else if (data.state === 'FAILURE') {
            setProcessing(false);
            clearInterval(intervalId);
            message.error(`Ошибка при обработке изображений: ${data.error}`);
          }
        } catch (error) {
          console.error('Error checking task status:', error);
        }
      }, 2000); // Check every 2 seconds
    }
    return () => clearInterval(intervalId);
  }, [taskId]);

  const handleUpload = async () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('file', file);
    });
    setUploading(true);
    setProcessing(true);

    try {
      const response = await fetch(`${API_URL}/process_images`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setTaskId(result.task_id);
    } catch (error) {
      message.error(`Ошибка при начале обработки изображений: ${error.message}`);
      setProcessing(false);
    } finally {
      setUploading(false);
    }
  };

  const handleImageSelect = (gameName, imagePath) => {
    setSelectedImages({
      ...selectedImages,
      [gameName]: imagePath,
    });
  };

  const downloadUpdatedExcel = async () => {
    setProcessing(true);
    try {
      const response = await fetch(`${API_URL}/update_excel`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(selectedImages),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'updated_games.xlsx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      message.success('Excel файл успешно обновлен и скачан');
    } catch (error) {
      message.error(`Ошибка при обновлении Excel файла: ${error.message}`);
    } finally {
      setProcessing(false);
    }
  };

  const handleFinalSelection = async () => {
    setProcessing(true);
    try {
      const response = await fetch(`${API_URL}/save_selected_images`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(selectedImages),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      // Обновляем finalResults с новыми путями к изображениям
      const updatedResults = result.results.map(item => ({
        ...item,
        image_url: `/images/final/${encodeURIComponent(item.game_name)}/${item.image_url.split('/').pop()}`
      }));
      setFinalResults(updatedResults);
      message.success('Выбранные изображения сохранены успешно');
      
      // Скачиваем обновленный Excel файл
      await downloadUpdatedExcel();
    } catch (error) {
      message.error(`Ошибка при сохранении выбранных изображений: ${error.message}`);
    } finally {
      setProcessing(false);
    }
  };

  const getImageUrl = (path, gameName) => {
    if (path.includes('/final/')) {
      // Если это финальное изображение, формируем правильный URL
      const encodedGameName = encodeURIComponent(gameName);
      return `${window.location.origin}/images/final/${encodedGameName}/${path.split('/').pop()}`;
    } else if (path && path.startsWith('/images')) {
      // Если изображение из папки processed
      const encodedGameName = encodeURIComponent(gameName);
      return `${window.location.origin}/images/processed/${encodedGameName}/${path.split('/').pop()}`;
    } else {
      console.error('Invalid image path:', path);
      return '';
    }
  };

  const columns = [
    {
      title: 'Название игры',
      dataIndex: 'game_name',
      key: 'game_name',
    },
    {
      title: 'Ссылка на изображение',
      dataIndex: 'image_url',
      key: 'image_url',
      render: (text, record) => (
        <a href={getImageUrl(text, record.game_name)} target="_blank" rel="noopener noreferrer">
          {getImageUrl(text, record.game_name)}
        </a>
      ),
    },
  ];

  return (
    <div>
      <Card title="Загрузка списка игр" style={{ marginBottom: 20 }}>
        <Upload
          beforeUpload={(file) => {
            setFileList([...fileList, file]);
            return false;
          }}
          onRemove={(file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
          }}
          fileList={fileList}
        >
          <Button icon={<UploadOutlined />}>Выбрать файл</Button>
        </Upload>
        <Button
          type="primary"
          onClick={handleUpload}
          disabled={fileList.length === 0 || processing}
          loading={uploading}
          style={{ marginTop: 16 }}
        >
          {uploading ? 'Обработка...' : 'Начать обработку изображений!'}
        </Button>
      </Card>

      {processing && (
        <Card>
          <Spin tip={`Обработка изображений... ${taskStatus || ''}`} />
        </Card>
      )}

      {imageResults.length > 0 && (
        <Card title="Результаты поиска изображений" style={{ marginBottom: 20 }}>
          {imageResults.map((result) => (
            <Card key={result.game_name} title={result.game_name} style={{ marginBottom: 10 }}>
              <div style={{ display: 'flex', overflowX: 'auto' }}>
                {result.images.map((imagePath, index) => (
                  <div key={index} style={{ marginRight: 10 }}>
                    <Image
                      width={200}
                      src={getImageUrl(imagePath, result.game_name)}
                      preview={false}
                      onClick={() => handleImageSelect(result.game_name, imagePath)}
                      style={{
                        border: selectedImages[result.game_name] === imagePath ? '2px solid blue' : 'none',
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                ))}
              </div>
            </Card>
          ))}
          <Button 
            type="primary" 
            onClick={handleFinalSelection} 
            disabled={processing || Object.keys(selectedImages).length === 0}
          >
            Сохранить выбранные изображения и скачать Excel
          </Button>
          <Button 
            onClick={downloadUpdatedExcel} 
            disabled={processing || Object.keys(selectedImages).length === 0}
            style={{ marginLeft: 10 }}
          >
            Скачать обновленный Excel
          </Button>
        </Card>
      )}

      {finalResults.length > 0 && (
        <Card title="Финальные результаты">
          <Table dataSource={finalResults} columns={columns} />
        </Card>
      )}
    </div>
  );
};

export default ImageSearch;