import React, { useState } from 'react';
import { Input, Button, message, Card, Image, Radio } from 'antd';

const API_URL = 'https://gamer-zone-xbox.online/api';

const ImageProcessing = () => {
  const [imageUrl, setImageUrl] = useState('');
  const [gameName, setGameName] = useState('');
  const [processedImageUrl, setProcessedImageUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [logoType, setLogoType] = useState('XBOX');
  const [addWarning, setAddWarning] = useState(false);
  const [isDigital, setIsDigital] = useState(false);
  const [consoleType, setConsoleType] = useState('PS4-PS5');

  const handleProcess = async () => {
    if (!imageUrl || !gameName) {
      message.error('Пожалуйста, введите URL изображения и название игры');
      return;
    }

    setLoading(true);
    try {
      const requestData = { 
        image_url: imageUrl, 
        game_name: gameName,
        logo_type: logoType,
        add_warning: addWarning,
        is_digital: isDigital,
        console_type: isDigital ? consoleType : null
      };
      
      console.log('Sending request with data:', requestData);
      console.log('Console type value:', consoleType);

      const response = await fetch(`${API_URL}/process_image_by_url`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setProcessedImageUrl(result.image_url);
      message.success('Изображение успешно обработано');
    } catch (error) {
      message.error(`Ошибка обработки изображения: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Card title="Обработка изображения по URL" style={{ marginBottom: 20 }}>
        <Input
          placeholder="Введите URL изображения"
          value={imageUrl}
          onChange={(e) => setImageUrl(e.target.value)}
          style={{ marginBottom: 10 }}
        />
        <Input
          placeholder="Введите название игры"
          value={gameName}
          onChange={(e) => setGameName(e.target.value)}
          style={{ marginBottom: 10 }}
        />
        
        <Radio.Group 
          onChange={(e) => {
            setIsDigital(e.target.value);
            if (e.target.value) {
              setLogoType('PS');
            }
          }} 
          value={isDigital}
          style={{ marginBottom: 10 }}
        >
          <Radio value={false}>Стандартная версия</Radio>
          <Radio value={true}>Цифровая версия</Radio>
        </Radio.Group>
        <br />

        {!isDigital && (
          <>
            <Radio.Group 
              onChange={(e) => setLogoType(e.target.value)} 
              value={logoType}
              style={{ marginBottom: 10 }}
            >
              <Radio value="XBOX">Xbox Logo</Radio>
              <Radio value="PS">PlayStation Logo</Radio>
            </Radio.Group>
            <br />
            <Radio.Group 
              onChange={(e) => setAddWarning(e.target.value)} 
              value={addWarning}
              style={{ marginBottom: 10 }}
            >
              <Radio value={true}>Добавить предупреждение</Radio>
              <Radio value={false}>Без предупреждения</Radio>
            </Radio.Group>
            <br />
          </>
        )}

        {isDigital && (
          <>
            <Radio.Group 
              onChange={(e) => {
                console.log('Selected console type:', e.target.value);
                setConsoleType(e.target.value);
              }} 
              value={consoleType}
              style={{ marginBottom: 10 }}
            >
              <Radio value="PS4-PS5">PS4/PS5</Radio>
              <Radio value="PS5">Только PS5</Radio>
            </Radio.Group>
            <br />
          </>
        )}

        <Button type="primary" onClick={handleProcess} loading={loading}>
          Обработать изображение
        </Button>
      </Card>

      {processedImageUrl && (
        <Card title="Обработанное изображение">
          <Image src={processedImageUrl} alt="Обработанное изображение игры" />
        </Card>
      )}
    </div>
  );
};

export default ImageProcessing;