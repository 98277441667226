import React, { useState } from 'react';
import { Button, Input, message, Upload, Form, Card, Table } from 'antd';
import { UploadOutlined, SyncOutlined, TagsOutlined } from '@ant-design/icons';

const API_URL = 'https://gamer-zone-xbox.online/api';

const PriceUpdater = () => {
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [results, setResults] = useState([]);
  const [form] = Form.useForm();

  const handleApiRequest = async (endpoint, data, successMessage) => {
    setUploading(true);
    try {
      const response = await fetch(`${API_URL}${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.error || `Ошибка сервера: ${response.status}`);
      }

      message.success(successMessage);
      setResults(result.results || []);
    } catch (error) {
      console.error(`Ошибка при запросе к ${endpoint}:`, error);
      message.error(`Ошибка: ${error.message}`);
    } finally {
      setUploading(false);
    }
  };

  const handleUpload = async () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('file', file);
    });
    setUploading(true);
    try {
      const response = await fetch(`${API_URL}/upload`, {
        method: 'POST',
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      message.success('Цены успешно обновлены');
      setResults(result.results || []);
    } catch (error) {
      console.error('Ошибка при обновлении цен:', error);
      message.error(`Ошибка: ${error.message}`);
    } finally {
      setUploading(false);
    }
  };

  const handleDiscountRemoval = async () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('file', file);
    });
    setUploading(true);
    try {
      const response = await fetch(`${API_URL}/remove_discounts`, {
        method: 'POST',
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      message.success('Скидки успешно удалены');
      setResults(result.results || []);
    } catch (error) {
      console.error('Ошибка при удалении скидок:', error);
      message.error(`Ошибка: ${error.message}`);
    } finally {
      setUploading(false);
    }
  };

  const onFinish = async (values) => {
    handleApiRequest('/update_exchange_rates', values, 'Курсы валют успешно обновлены');
  };

  const autoUpdateRates = async () => {
    try {
      const response = await fetch(`${API_URL}/auto_update_exchange_rates`);
      const result = await response.json();
      
      if (!response.ok) {
        throw new Error(result.error || 'Ошибка автоматического обновления');
      }
      
      message.success('Курсы валют автоматически обновлены');
      form.setFieldsValue(result.rates);
    } catch (error) {
      console.error('Ошибка автоматического обновления курсов валют:', error);
      message.error(`Ошибка: ${error.message}`);
    }
  };

  const columns = [
    {
      title: 'Название игры',
      dataIndex: 'game_name',
      key: 'game_name',
    },
    {
      title: 'Артикул продавца',
      dataIndex: 'seller_article',
      key: 'seller_article',
    },
    {
      title: 'Артикул WB',
      dataIndex: 'wb_article',
      key: 'wb_article',
    },
    {
      title: 'Текущая цена',
      dataIndex: 'current_price',
      key: 'current_price',
      render: (text) => `${text} ₽`,
    },
    {
      title: 'Новая цена',
      dataIndex: 'new_price',
      key: 'new_price',
      render: (text) => `${text} ₽`,
    },
    {
      title: 'Результат',
      dataIndex: 'result',
      key: 'result',
    },
  ];

  return (
    <div>
      <Card title="Загрузка таблицы цен" style={{ marginBottom: 20 }}>
        <Upload
          onRemove={(file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
          }}
          beforeUpload={(file) => {
            setFileList([...fileList, file]);
            return false;
          }}
          fileList={fileList}
        >
          <Button icon={<UploadOutlined />}>Выбрать файл</Button>
        </Upload>
        <Button
          type="primary"
          onClick={handleUpload}
          disabled={fileList.length === 0}
          loading={uploading}
          style={{ marginRight: 8, marginTop: 16 }}
        >
          {uploading ? 'Обработка...' : 'Обновить цены'}
        </Button>
        <Button
          type="default"
          icon={<TagsOutlined />}
          onClick={handleDiscountRemoval}
          disabled={fileList.length === 0}
          loading={uploading}
          style={{ marginTop: 16 }}
        >
          Убрать скидки
        </Button>
      </Card>
      
      <Card title="Обновление курсов валют" style={{ marginBottom: 20 }}>
        <Form
          form={form}
          name="exchangeRates"
          onFinish={onFinish}
          layout="inline"
        >
          <Form.Item
            name="USD"
            label="USD к RUB"
            rules={[{ required: true, message: 'Введите курс USD!' }]}
          >
            <Input type="number" step="0.01" />
          </Form.Item>
          <Form.Item
            name="TRY"
            label="TRY к RUB"
            rules={[{ required: true, message: 'Введите курс TRY!' }]}
          >
            <Input type="number" step="0.01" />
          </Form.Item>
          <Form.Item
            name="UAH"
            label="UAH к RUB"
            rules={[{ required: true, message: 'Введите курс UAH!' }]}
          >
            <Input type="number" step="0.01" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Обновить курсы вручную
            </Button>
          </Form.Item>
        </Form>
        <Button 
          icon={<SyncOutlined />} 
          onClick={autoUpdateRates} 
          style={{ marginTop: 16 }}
        >
          Автоматическое обновление курсов
        </Button>
      </Card>
      
      <Card title="Результаты обработки">
        <Table 
          dataSource={results} 
          columns={columns} 
          pagination={{ pageSize: 10 }}
          scroll={{ x: true }}
        />
      </Card>
    </div>
  );
};

export default PriceUpdater;